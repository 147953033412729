<template>
 <div class="container-fluid bg-light">
      <!-- <h1 class="display-4">JetDock Wisconsin</h1>
        <hr class="my-4">
      <p class="lead">
          Describe JetDock Wisconsin here
        </p> -->
    <!-- <div class="about contained"> -->
	<div class="row">
		<div>
			<div>
        <div class="text-start">
				<h1 class="heading1">Advantages of Jet Dock Boat Lifts and Floating Docks</h1>
				<p>Our systems make boating easier and more enjoyable with their ease of customization and operation. A Jet Dock solution has many advantages over other lift options including their versatility, durability, boat accommodation, and safety. Spend less time turning cranks and more time on the water with our simple and safe drive-on lift system. Jet Dock owners estimate that they find themselves being able to use their watercraft up to 3 times more after purchase due to the ease of use and ownership of our <a href="/boat-lifts/">drive-on lift systems</a> and <a href="/floating-docks/">floating dock</a> solutions.</p>
        </div>
        <div class="row text-start" > 
          <div class="col">
            <img class="img-fluid" src="https://www.jetdock.com/img/about-dock-graphic-mobile.png" alt="Graphic for a drive on boat dock" />
          </div>
          <div class="col-9">
            <h3 class="heading3 color">JetDock systems are versatile </h3><br />
            <p>
              Jet Dock’s can be used for as walkways or <a href="https://www.jetdock.com/floating-docks/">drive-on docks</a> that are both extremely lightweight and durable - making traditional boat lift technology obsolete. Fully portable, modular, and interchangable, our floating boat lifts operate in any water conditions including deep or shallow water and saltwater or freshwater. Jet Dock boat lifts are designed to work effectively in any kind of tide, current change, or even waves.
            </p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col">
            <img class="img-fluid" src="https://www.jetdock.com/img/about-wrench-graphic-mobile.png" alt="maintenance free boat lifts symbol with wrench" />
          </div>
          <div class="col-10">
            <h3 class="heading3 color">Our boat lifts and floating dock systems are durable and virtually maintenance free</h3><br />
            <p>
              All Jet Dock systems are made with quality materials that are non-polluting, resistant to utlraviolet damage, require no electricity, and are non-corroding. Jet Dock’s design also makes them easy to transport without the use of a trailer. Spend more time enjoying life on the water and less time maintaining and hauling your boat lift with Jet Dock.
            </p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col">
            <img class="img-fluid" src="https://www.jetdock.com/img/about-degree-graphic-mobile.png" alt="270 degree symbolizing the walk around platform on floating boat dock" />
          </div>
          <div class="col-10">
            <h3 class="heading3 color">Owning a Jet Dock system makes maintaining your boat easier</h3><br />
            <p>
              With a 270° walk-around platform, Jet Dock systems make fueling, servicing, covering, and securing your boat a breeze! Safely perform maintenance on your boat without worrying about your footing.
            </p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col">
            <img class="img-fluid" src="https://www.jetdock.com/img/about-variety-graphic-mobile.png" alt="Symbol showing JetDock floating docks and lifts work with a variety of watercrafts" />
          </div>
          <div class="col-10">
            <h3 class="heading3 color">Jet Dock works with a variety of watercraft</h3><br />
            <p>
              Whether your prized boat is a powerboat, dinghy, kayak, Jet Ski, sea plane, or pontoon boat - a Jet Dock <a href="https://www.jetdock.com/boat-lifts/">boat lift</a> is the solution for you. We offer different systems for different boat styles including static, air-assisted, and multi-hull. Our custom-designed boat lifts can also accommodate most boat lengths, making them one of the most versatile boat lift options in the world!
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <img class="img-fluid" src="https://www.jetdock.com/img/about-custom-graphic-mobile.png" alt="Floating boat docks in customized shapes including t-shape and u-shape" />
          </div>
          <div class="col-10">
            <h3 class="heading3 color">Customize your Jet Dock</h3><br />
            <p>
              With Jet Dock, your floating boat dock and boat lift systems are designed to suit your watercraft needs. With its modular design, JetDock systems are available in any size, arrangement, or shape. Need to change your floating walkway to accommodate more boats? Transform your <a href="https://www.jetdock.com/products/conventional-tshape-walkway.asp">“T”-shaped dock</a> to a <a href="https://www.jetdock.com/products/conventional-ushape-walkway.asp">“U”-shaped dock</a> easily to customize your dock as needed.
            </p>
          </div>
        </div>
      <div class="row text-start">
        <h3>Have more questions about JetDock Systems?</h3>
        <p>
        Jet Dock Systems not only provides excellence in product, we also provide excellence in customer service. <br />
        <router-link to="/contactform">Contact us today</router-link> to speak to a Jet Dock representative about our floating dock and drive-on boat lift systems.
        </p>
      </div>
			</div>
		</div>
</div>
 </div>
</template>
